import request, { baseUrl } from "@/utils/request";
export function getCharacters() {
  return request({
    url: `${baseUrl.api}/characterInfo/list`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

export function getCharacter(id) {
  return request({
    url: `${baseUrl.api}/characterInfo/detail?id=${id}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}
